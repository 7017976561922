Vue.component('vue-nav-mobile-submenu', {
    delimiters: ['${', '}'],

    template: '#vue-nav-mobile-submenu-template',

    props: ['label'],

    data() {
        return {
            submenu_open: false,
        }
    },

    methods: {
        toggleSubmenu() {
            this.submenu_open = ! this.submenu_open;
            this.$parent.submenuUpdate(this.submenu_open);
        },
    },
});
